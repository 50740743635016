<template>
  <a-modal

    :width="800"
    :visible="visible"
    :footer="null"
    @cancel="handleCancel">


    <a-card :bordered="false">
      <detail-list title="注销信息">
        <detail-list-item term="注销手机号">{{ this.record.mobile }}</detail-list-item>
        <detail-list-item term="注销原因">{{ this.record.reason}}</detail-list-item>
        <detail-list-item term="审核状态">{{ this.record.userType | userTypeFilter}}</detail-list-item>
        <detail-list-item v-if="this.record.userType != 1" term="代理区域">{{ this.record.areap + this.record.areac + this.record.areax  }}</detail-list-item>
        <detail-list-item term="审核意见">{{ this.record.birthday }}</detail-list-item>

      </detail-list>
    </a-card>

  </a-modal>
</template>

<script>

    import DetailList from '@/components/DetailList'
    import ABadge from "ant-design-vue/es/badge/Badge"

    const DetailListItem = DetailList.Item;

    export default {
        name: "UserDetailModal",
        components: {
            ABadge,
            DetailList,
            DetailListItem,
        },
        data () {
            return {
                visible: false,
                vipStatusH:"",
                record:{}
            }
        },
        created () {
        },
        filters: {
            statusFilter(status) {
                const statusMap = {
                    1: '正常',
                    2: '注销'
                };
                return statusMap[status]
            },
            userTypeFilter(status) {
                const statusMap = {
                    1: '普通用户',
                    2: '门店',
                    3: '代理商',
                    4: '合伙人',
                };
                return statusMap[status]
            },
        },
        methods: {
            edit (record) {
                this.record = record;

                this.visible = true;
            },
            handleCancel () {
                this.visible = false;
            }
        }
    }
</script>

<style lang="less" scoped>

</style>